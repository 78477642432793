import template from "./request-consultation.html";

import Vue from "vue";
import Component from "vue-class-component";
import NavigationBar from '../../components/navigationBar/NavigationBar';
import Footer from "../../components/footer/Footer";

@Component({
    template,
    components: {
        NavigationBar,
        Footer
    }
})
export default class FirstLaunchController extends Vue
{
    private mounted(): void
    {
        var qs, js, q, s, d = document,
            gi = d.getElementById,
            ce = d.createElement,
            gt = d.getElementsByTagName,
            id = "typef_orm_share",
            b = "https://embed.typeform.com/";
        if (!gi.call(d, id))
        {
            js = ce.call(d, "script");
            js.id = id;
            (js as HTMLScriptElement).src = b + "embed.js";
            q = gt.call(d, "script")[0];
            q.parentNode!.insertBefore(js, q)
        }
    }
}